* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Open Sans", sans-serif; }

body {
  margin: 0; }

.main {
  padding-left: 20px; }

p {
  margin: 0; }

a {
  text-decoration: none;
  color: black;
  cursor: pointer; }
  a:hover {
    text-decoration: underline; }

img {
  max-width: 100%; }

button {
  font-size: 16px;
  cursor: pointer; }

.content-wrapper {
  margin: 0 auto;
  max-width: 1200px; }

.row {
  padding: 0 20px; }

.center {
  text-align: center;
  margin: auto; }

.topnav {
  min-height: 60px; }
  .topnav .navbar-form {
    display: inline; }
  .topnav__logo {
    max-width: 33%; }
  .topnav__links {
    float: none;
    padding: 10px;
    max-width: 100%;
    position: relative;
    text-align: center; }
    .topnav__links a {
      padding: 0 15px;
      font-size: 12px;
      color: #6E6F71;
      text-transform: uppercase; }
    .topnav__links img {
      vertical-align: text-top;
      max-height: 20px;
      padding: 0 15px; }
  .topnav__search {
    border: 1px solid #333;
    display: none;
    font-size: 12px;
    width: 100%;
    margin-top: 5px;
    height: 30px; }
  .topnav__searchArrow {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #0066CC;
    position: absolute;
    top: 60%;
    right: -19px;
    cursor: pointer;
    display: none; }

.menu-btn {
  padding: 10px;
  background-color: #1B4E8E;
  text-align: right; }
  .menu-btn div {
    position: absolute;
    left: 100%;
    top: 64%;
    padding-right: 8px;
    margin-top: -0.50em;
    line-height: 1.2;
    font-size: 18px;
    font-weight: 200;
    vertical-align: middle;
    z-index: 99; }
  .menu-btn span {
    display: block;
    width: 25px;
    height: 3px;
    margin: 4px 0;
    background: #FFF;
    z-index: 99;
    border-radius: 10px;
    position: relative;
    left: calc(100% - 40px); }

.responsive-menu {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative; }
  .responsive-menu:before, .responsive-menu:after {
    content: " ";
    display: table; }
  .responsive-menu:after {
    clear: both; }
  .responsive-menu ul {
    display: block;
    list-style: none; }
  .responsive-menu a {
    padding: 10px 15px;
    color: #FFF;
    display: block;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold; }
    .responsive-menu a:hover {
      text-decoration: none; }
  .responsive-menu li {
    position: relative;
    text-align: center; }
  .responsive-menu .outer-list {
    float: none;
    border-bottom: 1px solid #8EB4E0;
    width: 100%;
    background-color: #1B4E8E; }
    .responsive-menu .outer-list__arrow {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #FFF;
      position: absolute;
      top: 12px;
      right: 30px; }
      .responsive-menu .outer-list__arrow.rotate {
        -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
                transform: rotate(180deg); }
    .responsive-menu .outer-list:last-child {
      border-right: none; }
    .responsive-menu .outer-list > a {
      width: 60%;
      margin: 0 auto; }
    .responsive-menu .outer-list ul {
      padding-left: 0;
      width: 100%;
      display: none; }
      .responsive-menu .outer-list ul.open {
        display: block; }
    .responsive-menu .outer-list.hover ul {
      left: 0;
      padding-left: 0;
      position: static; }
  .responsive-menu .inner-list {
    position: relative;
    z-index: 100;
    border: 1px solid #B2B6B7;
    border-top: none; }
    .responsive-menu .inner-list a {
      background-color: #EEEAEA;
      color: #333;
      font-size: 12px;
      text-transform: capitalize;
      color: #494848; }
      .responsive-menu .inner-list a:hover {
        background-color: #C9E7F9; }

.expand {
  display: block !important; }

.welcome {
  background-color: #EEEAEA;
  padding: 5px 10px; }
  .welcome__user {
    text-align: center;
    margin: 0;
    font-size: 18px;
    padding-top: 5px;
    font-weight: normal; }
  .welcome__content {
    padding: 10px;
    text-align: center;
    margin: 0;
    font-size: 18px;
    padding-top: 5px; }
  .welcome__message {
    text-align: center;
    margin: 0;
    font-size: 18px;
    padding-top: 5px; }
  .welcome__message {
    text-align: center;
    padding: 10px 0;
    font-size: 15px; }
  .welcome--alerts {
    background-color: #4d9d2d;
    color: #FFF; }
  .welcome--announcements {
    background-image: url("../images/announcements-gradient.jpg"); }
  .welcome__content {
    padding: 20px 0; }

.quickLinks {
  font-weight: 700;
  position: relative;
  border-top: 1px solid #FFF; }
  .quickLinks .hst-cmseditlink {
    right: 50px; }
  .quickLinks__header {
    background-color: #1B4E8E;
    padding: 10px 20px;
    position: relative; }
    .quickLinks__header h2 {
      margin: 0;
      color: #FFF;
      font-family: "Open Sans", sans-serif; }
  .quickLinks__arrow-down {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #FFF;
    position: absolute;
    top: 40%;
    right: 30px;
    cursor: pointer; }
  .quickLinks__sub-menu {
    padding: 0;
    margin: 0; }
    .quickLinks__sub-menu li {
      list-style-type: none;
      border-bottom: 1px solid #FFF;
      padding: 10px 20px;
      background-image: url("../images/left-nav_bottom.gif");
      background-repeat: no-repeat;
      background-size: cover; }
      .quickLinks__sub-menu li:hover {
        background-image: url("../images/left-nav_hoverbg.jpg");
        background-repeat: repeat-y;
        background-size: contain; }
    .quickLinks__sub-menu a {
      text-decoration: none;
      color: #333;
      font-family: "Open Sans", sans-serif; }
  .quickLinks .closed {
    display: none; }
  .quickLinks .rotate {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg); }

.imageBar {
  background-color: #F8E2BE; }
  .imageBar::after {
    content: "";
    display: block;
    clear: both; }
  .imageBar__content {
    padding-top: 30px;
    padding-bottom: 10px;
    text-align: center;
    min-height: 200px; }
    .imageBar__content a {
      text-decoration: underline; }
  .imageBar__button {
    text-decoration: none !important;
    background-color: #1B4E8E;
    color: white;
    display: block;
    border-radius: 6px;
    margin-right: 10px;
    margin-bottom: 20px;
    padding: 10px;
    -webkit-box-shadow: 0 5px 20px black;
            box-shadow: 0 5px 20px black; }
  .imageBar__image {
    float: left;
    width: 33%; }

.contentComponent .contentContainer {
  border-radius: 8px;
  margin: 20px;
  background-image: url("../images/tile-gradient.jpg");
  background-size: contain;
  border: 1px solid #B2B6B7;
  text-align: center; }
  .contentComponent .contentContainer:after {
    display: table;
    clear: both;
    content: ""; }
  .contentComponent .contentContainer__title {
    padding: 15px 0;
    border-bottom: 2px solid #EEEAEA;
    font-weight: 700;
    margin: 0 20px;
    font-size: 24px;
    color: #1B4E8E;
    text-align: left; }
  .contentComponent .contentContainer__subtitle {
    font-size: 20px;
    color: #1B4E8E;
    margin-bottom: 10px; }
  .contentComponent .contentContainer__content {
    padding: 10px 20px;
    text-align: left;
    display: table; }
    .contentComponent .contentContainer__content a {
      color: #0066CC;
      font-weight: 700; }
    .contentComponent .contentContainer__contentCol {
      display: block;
      width: 100%;
      float: left;
      padding: 0 10px 40px 0; }
      .contentComponent .contentContainer__contentCol:nth-child(odd) {
        clear: left;
        padding-left: 0; }
      .contentComponent .contentContainer__contentCol h5 {
        color: #1B4E8E; }
  .contentComponent .contentContainer__button {
    padding: 10px 15px;
    background-color: #1B4E8E;
    color: #FFF;
    border: none;
    border-radius: 8px;
    margin-top: 15px; }
    .contentComponent .contentContainer__button:hover {
      background-color: #8EB4E0; }
  .contentComponent .contentContainer--agentSupport {
    background-image: none;
    border-radius: 0;
    border: none; }
    .contentComponent .contentContainer--agentSupport .contentRow__phoneEmailCol {
      text-align: left; }
      .contentComponent .contentContainer--agentSupport .contentRow__phoneEmailCol img {
        vertical-align: middle; }
      .contentComponent .contentContainer--agentSupport .contentRow__phoneEmailCol p {
        display: inline;
        font-size: 16px;
        font-weight: 600;
        color: #333; }
    .contentComponent .contentContainer--agentSupport .contentContainer__title {
      font-size: 14px;
      text-align: center;
      color: #333;
      border-bottom: none;
      clear: both; }
    .contentComponent .contentContainer--agentSupport .contentContainer__subtitle {
      display: none; }
    .contentComponent .contentContainer--agentSupport .contentContainer__text {
      color: #1B4E8E;
      font-style: italic; }
    .contentComponent .contentContainer--agentSupport .contentContainer__content {
      display: block; }
  .contentComponent .contentContainer--imageWrapped .contentContainer__image {
    float: left;
    padding: 20px; }
  .contentComponent .contentContainer--imageAbove .contentContainer__image {
    margin-top: 20px; }
  .contentComponent .contentContainer--noImage .contentContainer__image {
    display: none; }
  .contentComponent .contentContainer--salesTile {
    position: relative; }
    .contentComponent .contentContainer--salesTile .contentContainer__title {
      background-color: #1B4E8E;
      color: #FFF;
      text-align: center;
      border-radius: 8px 8px 0 0;
      font-size: 24px;
      padding: 10px;
      margin: 0; }
    .contentComponent .contentContainer--salesTile .contentContainer__image {
      margin-top: 20px; }
    .contentComponent .contentContainer--salesTile .contentContainer__button {
      position: absolute;
      margin: 0 20px;
      left: 0;
      bottom: 20px; }
  .contentComponent .contentContainer--productTile .contentContainer__title {
    background-color: #1B4E8E;
    color: #FFF;
    text-align: center;
    border-radius: 8px 8px 0 0;
    font-size: 24px;
    padding: 10px;
    margin: 0; }
  .contentComponent .contentContainer--productTile .contentContainer__image {
    width: 100%;
    border-bottom: 5px solid #F9B244; }
  .contentComponent .contentContainer--linkList .contentContainer__title {
    color: #1B4E8E; }
  .contentComponent .contentContainer--linkList a {
    color: #333;
    margin: 0; }
  .contentComponent .contentContainer--linkList ul {
    padding-left: 20px; }
  .contentComponent .contentContainer--linkList li {
    font-size: 20px;
    list-style-type: none; }
  .contentComponent .contentContainer--linkList .fullCol:before {
    content: "";
    border-color: transparent #1B4E8E;
    border-style: solid;
    border-width: 0.35em 0 0.35em 0.45em;
    display: block;
    height: 0;
    width: 0;
    left: -1em;
    top: 0.9em;
    position: relative; }
  .contentComponent .contentContainer--linkList .contentContainer__button {
    margin-top: 0; }
  .contentComponent .contentContainer--linkList .textCol {
    float: left;
    width: 75%; }
    .contentComponent .contentContainer--linkList .textCol:before {
      content: "";
      border-color: transparent #1B4E8E;
      border-style: solid;
      border-width: 0.35em 0 0.35em 0.45em;
      display: block;
      height: 0;
      width: 0;
      left: -1em;
      top: 0.9em;
      position: relative; }
    .contentComponent .contentContainer--linkList .textCol p {
      padding: 0; }
    .contentComponent .contentContainer--linkList .textCol__description {
      font-size: 14px; }
  .contentComponent .contentContainer--linkList .imageCol {
    float: left;
    width: 25%;
    padding-top: .9em; }
    .contentComponent .contentContainer--linkList .imageCol img {
      border-bottom: none;
      padding-right: 30px;
      max-width: 100px;
      padding-left: 10px; }
  .contentComponent .contentContainer--linkList ul:after {
    content: "";
    clear: both;
    display: block;
    height: 0; }

.contentComponent--generalContent .contentContainer {
  background-image: none;
  border-radius: 0;
  border: none;
  margin: 0; }
  .contentComponent--generalContent .contentContainer__content {
    padding: 10px 0;
    display: block; }
  .contentComponent--generalContent .contentContainer__title {
    margin: 0; }
  .contentComponent--generalContent .contentContainer__image {
    float: left;
    padding: 20px; }

.contentComponent--singleTile .contentRow .contentContainer {
  display: block;
  width: calc(100% - 40px); }
  .contentComponent--singleTile .contentRow .contentContainer__image {
    padding-bottom: 5px;
    padding-right: 15px; }
  .contentComponent--singleTile .contentRow .contentContainer__content {
    display: block; }

.contentComponent .underline {
  text-decoration: underline;
  margin-bottom: 20px; }

.footer {
  clear: both; }
  .footer a {
    color: #1B4E8E;
    display: inline; }
  .footer__links {
    text-align: right;
    padding: 40px 0;
    clear: both;
    position: relative; }
    .footer__links a {
      color: #6E6F71;
      display: inline; }
      .footer__links a:hover::after {
        text-decoration: none; }
      .footer__links a::after {
        content: " | ";
        display: inline-block;
        padding: 0 20px; }
      .footer__links a:last-child::after {
        content: ""; }
      .footer__links a:last-child::after {
        content: ""; }
  .footer-row {
    display: table;
    width: 100%; }
  .footer__image {
    padding: 0 10px 10px 10px;
    display: table-cell; }
  .footer__info {
    color: #6E6F71;
    font-size: 12px;
    padding: 0 10px 10px 10px;
    max-width: 75%;
    display: table-cell;
    vertical-align: bottom; }
  .footer:after {
    content: "";
    clear: both;
    display: block;
    height: 0; }

.agentMaterials__filters {
  display: block;
  width: 80%;
  margin: 10px 0; }

.agentMaterials__radioButtons, .agentMaterials__selectGroup, .agentMaterials__button {
  display: block; }

.agentMaterials #searchSelectGroup {
  display: none; }

.agentMaterials__radioButtons label {
  font-weight: 700;
  color: #1B4E8E; }

.agentMaterials__selectGroup {
  margin: 40px 0; }

@input screen-tablet() {
  .agentMaterials__selectGroup {
    margin: 0; } }
  .agentMaterials__selectGroup label {
    display: block;
    position: relative;
    top: -15px;
    color: #1B4E8E;
    font-weight: 700; }
  .agentMaterials__selectGroup select,
  .agentMaterials__selectGroup input {
    width: 90%;
    border: 1px solid #333;
    height: 23px;
    font-size: 14px;
    background-color: #FFF; }

.agentMaterials__button {
  border: none;
  background: #3c7ac2;
  background: -webkit-gradient(linear, left top, left bottom, from(#3c7ac2), to(#2267ab));
  background: -o-linear-gradient(top, #3c7ac2 0%, #2267ab 100%);
  background: linear-gradient(to bottom, #3c7ac2 0%, #2267ab 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3c7ac2', endColorstr='#2267ab',GradientType=0 );
  color: #FFF;
  border-radius: 8px;
  padding: 5px 40px;
  position: relative;
  top: 20px; }

.agentMaterials .materialsTableWrapper {
  overflow-x: auto; }

.agentMaterials .materialsTable {
  width: 100%;
  margin-top: 50px;
  border-collapse: collapse;
  border-top: 1px solid #B2B6B7;
  border-bottom: 1px solid #B2B6B7;
  font-size: 14px; }
  .agentMaterials .materialsTable th {
    background-color: #C9E7F9;
    padding: 5px 15px;
    width: calc(100% / 6);
    cursor: pointer;
    border-left: 1px solid #B2B6B7;
    border-right: 1px solid #B2B6B7; }
    .agentMaterials .materialsTable th:hover {
      text-decoration: underline; }
    .agentMaterials .materialsTable th:first-child {
      width: 50%; }
  .agentMaterials .materialsTable th.active {
    background-color: #8EB4E0; }
    .agentMaterials .materialsTable th.active .materialsTable__arrow-down {
      border-bottom: 10px solid #333; }
  .agentMaterials .materialsTable tbody tr:nth-child(even) {
    background-color: #EEEAEA; }
  .agentMaterials .materialsTable td {
    text-align: center;
    padding: 10px 20px;
    border-left: 1px solid #B2B6B7;
    border-right: 1px solid #B2B6B7; }
  .agentMaterials .materialsTable__header {
    margin-right: 10px; }
  .agentMaterials .materialsTable__arrow-down {
    vertical-align: middle;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #B2B6B7; }
    .agentMaterials .materialsTable__arrow-down.rotate {
      -webkit-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
              transform: rotate(180deg); }
  .agentMaterials .materialsTable__titleCell {
    text-align: left !important; }
    .agentMaterials .materialsTable__titleCell a {
      color: #00F; }
    .agentMaterials .materialsTable__titleCell img {
      max-width: 100%;
      vertical-align: middle; }

.agentForms .materialsTable td {
  padding: 5px 20px; }

.pageTitle {
  margin: 25px 0 20px 0;
  border-bottom: 1px dotted #6E6F71;
  padding-bottom: 10px;
  color: #1B4E8E;
  font-size: 24px;
  font-weight: 400; }
  .pageTitle--noSpace {
    margin: 30px 0;
    margin-bottom: 0;
    padding-bottom: 10px;
    color: #1B4E8E;
    font-size: 24px;
    font-weight: 400; }

.textComponent {
  padding: 0 20px; }

.agentList {
  padding: 0 20px; }
  .agentList__title {
    margin: 0;
    color: #1B4E8E; }
  .agentList__list {
    list-style-type: none;
    padding-left: 0; }
    .agentList__list li {
      clear: both;
      padding: 20px;
      min-height: 140px; }
      .agentList__list li:nth-child(even) {
        background-color: #EEEAEA; }
    .agentList__list p:first-of-type {
      display: table; }
  .agentList__image {
    float: left;
    margin-right: 15px; }
  .agentList__name {
    color: #1B4E8E;
    font-weight: bold; }

.contactSection {
  text-align: center; }
  .contactSection__group {
    width: 100%;
    float: left;
    margin-bottom: 10px; }
  .contactSection__text {
    display: inline; }
  .contactSection__image {
    vertical-align: middle; }
  .contactSection__description {
    margin: 40px auto;
    width: 80%;
    color: #1B4E8E;
    font-style: italic; }

.largeImageSection {
  border-collapse: collapse;
  width: 100%;
  min-height: 250px;
  display: table;
  margin-top: 25px;
  margin-bottom: 20px; }
  .largeImageSection__row {
    display: table-row; }
  .largeImageSection__content {
    width: 100%;
    padding: 30px;
    display: block; }
    .largeImageSection__content a {
      color: #1B4E8E; }
  .largeImageSection__imageCell {
    width: 100%;
    padding: 0;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
    .largeImageSection__imageCell img {
      width: 100%;
      vertical-align: top; }
    .largeImageSection__imageCell--desktop {
      display: none; }
  .largeImageSection--imageFull {
    min-height: 0; }
    .largeImageSection--imageFull .largeImageSection__imageCell {
      background-size: contain; }
    .largeImageSection--imageFull .largeImageSection__content {
      display: none; }
  .largeImageSection--bottomBorder {
    border-bottom: 20px solid #EEEAEA; }

.thinImageSection--imageHalf {
  margin-top: initial;
  margin-bottom: 50px;
  border-collapse: initial;
  min-height: 0px; }
  .thinImageSection--imageHalf__content h4 {
    color: #0c53ab;
    font-weight: 100; }

.faq {
  padding: 20px; }
  .faq a {
    color: #1B4E8E;
    cursor: pointer; }
  .faq ul {
    list-style-type: none;
    padding: 0; }
  .faq li {
    margin-bottom: 20px; }
  .faq__question {
    background-color: #C9E7F9;
    position: relative;
    padding: 10px;
    padding-right: 40px;
    color: #1B4E8E; }
  .faq__expand {
    position: absolute;
    top: calc(50% - 7px);
    right: 15px; }
    .faq__expand:hover {
      cursor: pointer; }
  .faq__answer {
    padding: 15px 10px 0 10px;
    display: none; }

.pdf__selectLabel {
  display: block;
  color: #1B4E8E;
  font-weight: 700; }

.pdf__select {
  float: left;
  padding-bottom: 20px; }
  .pdf__select select {
    width: 200px;
    font-size: 14px;
    background-color: #FFF; }

.pdf__newWindow {
  text-align: right; }
  .pdf__newWindow a {
    color: #1B4E8E;
    font-weight: 700; }
  .pdf__newWindow img {
    vertical-align: middle; }

.pdf iframe {
  width: 100%;
  height: 500px;
  border: 1px solid #3C3C3C; }

.news {
  padding: 20px; }
  .news--content {
    padding: 0; }
  .news__buttons a.news__button {
    border: none;
    background-color: #0066CC;
    color: #FFF;
    display: inline-block;
    padding: 5px 20px;
    border-radius: 8px;
    width: 100%;
    margin-right: 6px;
    text-align: center; }
    .news__buttons a.news__button:last-child {
      margin-right: 0; }
    .news__buttons a.news__button.active {
      background-color: #8EB4E0; }
    .news__buttons a.news__button:hover {
      background-color: #8EB4E0; }
  .news .event {
    padding: 10px 0; }
    .news .event a {
      color: #0066CC; }
      .news .event a:hover .event__date {
        color: #0066CC; }
    .news .event__date {
      color: #6E6F71;
      font-weight: 700; }
    .news .event__title {
      color: #0066CC;
      font-weight: 700; }
    .news .event__description {
      color: #6E6F71; }

.divider {
  height: 50px;
  background-color: #EEEAEA; }

.ax-layout-columns .ax-layout-columns--2col50 {
  width: 100%; }

.ax-layout-columns .ax-layout-columns--1col {
  width: 100%;
  float: left; }
  .ax-layout-columns .ax-layout-columns--1col .ax-layout-column {
    width: 100%;
    float: left; }

.page-root .content-wrapper {
  max-width: 955px; }

.login {
  clear: both;
  font-family: Arial;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 60px; }
  .login a {
    color: #336699; }
  .login h2 {
    color: #336699;
    text-align: center;
    background-color: #FFCC66;
    padding: 8px;
    font-size: 14px;
    font-weight: bold; }
  .login .left-column {
    float: left;
    width: 365px; }
    .login .left-column div.errors {
      margin: 0; }
    .login .left-column div.error-message {
      margin: 0 0 20px;
      border: 1px solid #8d313d;
      border-radius: 3px;
      background: #f2dede;
      padding: 0 12px;
      font-size: 12px;
      line-height: 33px;
      color: #8d313d; }
      .login .left-column div.error-message.success {
        background: rgba(0, 180, 0, 0.3);
        border: 2px solid #090;
        color: black; }
    .login .left-column form {
      margin: 35px auto 35px;
      width: 70%; }
      .login .left-column form label {
        display: block;
        margin: 0 0 3px;
        font-weight: bold;
        font-size: 11px;
        line-height: 15px;
        color: #666;
        text-transform: uppercase; }
        .login .left-column form label.accessibility-label {
          display: none; }
      .login .left-column form input, .login .left-column form button {
        display: block;
        width: 100%; }
      .login .left-column form input {
        margin: 0 0 20px;
        border: 1px solid #ccc;
        border-radius: 3px;
        background: #fff;
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
                box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
        height: 35px;
        padding: 5px 12px;
        font-size: 12px;
        line-height: 23px;
        color: #666;
        -webkit-transition: border 250ms ease-in;
        -o-transition: border 250ms ease-in;
        transition: border 250ms ease-in; }
        .login .left-column form input:focus {
          border: 1px solid #0093c3;
          outline: 0; }
      .login .left-column form button[type=submit] {
        -webkit-appearance: button;
        margin: 30px 0 0;
        border: 0;
        background: #0066cc;
        border-radius: 3px;
        padding: .809em 1.618em;
        font-size: 12px;
        color: #ffffff;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
        -webkit-transition: background-color 250ms ease 0s;
        -o-transition: background-color 250ms ease 0s;
        transition: background-color 250ms ease 0s; }
        .login .left-column form button[type=submit]:hover {
          background: #0080ff; }
  .login .right-column {
    display: table-cell; }
  .login .iframe-container {
    overflow: hidden;
    width: 290px;
    height: 540px;
    margin: auto;
    position: relative; }
    .login .iframe-container iframe {
      width: 340px;
      height: 688px;
      position: absolute;
      top: -95px;
      left: -25px; }
    .login .iframe-container .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #FFF;
      z-index: 999; }
      .login .iframe-container .overlay img {
        width: 50px; }
  .login h3 {
    color: #336699;
    font-weight: bold;
    font-size: 100%; }
  .login p {
    padding: 0 10px 10px 0; }

.search-results__container {
  float: left;
  margin-left: 20px; }

.search-results__pagination li {
  float: left;
  list-style: none;
  padding: 10px;
  font-size: 16px; }

.search-results__pagination a {
  color: #0066CC; }

.breadcrumbs {
  height: 21px;
  list-style: none;
  font-size: 11px;
  padding-left: 10px;
  margin: 4px 0 0; }
  .breadcrumbs li {
    color: #003366;
    float: left;
    padding: 0 2px; }
    .breadcrumbs li:last-of-type:after {
      content: ''; }
    .breadcrumbs li:after {
      content: '>'; }
    .breadcrumbs li a {
      color: inherit; }

.changePassword--header {
  color: #0066CC;
  font-weight: 800;
  padding-bottom: 15px; }

.changePassword--form {
  background-color: #C9E7F9;
  padding: 20px; }

.changePassword label {
  display: block;
  margin-bottom: 10px;
  color: #1B4E8E;
  font-weight: 800; }

.changePassword input {
  display: block;
  margin-top: 5px;
  width: 180px; }

.changePassword--row {
  display: table;
  table-layout: fixed;
  width: 100%; }

.changePassword--left-col {
  width: 100%; }

.changePassword--right-col {
  width: 100%; }
  .changePassword--right-col p {
    font-weight: 800;
    color: #0066CC; }
  .changePassword--right-col ul {
    list-style-type: none;
    padding: 0;
    margin-top: 5px;
    color: #6E6F71;
    font-size: 13px;
    letter-spacing: -0.5px; }

.changePassword input.changePassword--submit {
  background-color: #0066CC;
  color: #FFF;
  border-radius: 6px;
  font-size: 15px;
  width: 110px;
  margin: 25px 0;
  border: none; }

.changePassword .error {
  padding: 5px;
  background: rgba(180, 0, 0, 0.3);
  border: 2px solid #900;
  margin-bottom: 10px;
  display: block; }

.changePassword .success {
  padding: 5px;
  background: rgba(0, 180, 0, 0.3);
  border: 2px solid #090;
  margin-bottom: 10px;
  display: block; }

.reportlist ul {
  margin: 0.75em 0;
  padding-bottom: 50px;
  color: #6e6f71;
  font-size: 13px;
  letter-spacing: -.5px; }

.reportlist p {
  line-height: 10%;
  font-weight: bold;
  color: #0c53ab; }

.arrow-list {
  list-style: none !important; }
  .arrow-list li:before {
    content: "";
    border-color: transparent #146996;
    border-style: solid;
    border-width: 0.35em 0 0.35em 0.45em;
    left: -1em;
    top: .3em;
    float: left;
    position: relative; }

.underwriting {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: Open Sans,sans-serif; }
  .underwriting input.underwriting--submit {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
        touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .underwriting .underwriting--submit:hover {
    color: #fff;
    background-color: #286090;
    border-color: #204d74; }
  @media (min-width: 768px) {
      .topnav__links {
        max-width: 67%;
        float: right; }
      .topnav__search {
        width: auto;
        margin-top: 0;
        height: auto; }
      .topnav__searchArrow {
        top: 40%; }
    .welcome {
      margin-top: 25px; }
      .welcome__user {
        text-align: left; }
      .welcome__content {
        text-align: left; }
      .welcome__message {
        text-align: left; }
      .welcome__message {
        text-align: left; }
      .quickLinks__header {
        border-radius: 6px 6px 0 0;
        margin-top: 15px;
        padding: 4px 20px;
        font-size: 12px; }
      .quickLinks__arrow-down {
        display: none; }
        .quickLinks__sub-menu li:last-child {
          border-radius: 0 0 6px 6px; }
      .quickLinks .closed {
        display: block; }
        .contentComponent .contentContainer--agentSupport .contentRow__phoneEmailCol {
          width: 50%;
          float: left; }
        .contentComponent .contentContainer--agentSupport .contentRow__phoneEmailCol--full {
          width: 100%; }
    .agentMaterials__filters {
      display: table;
      table-layout: fixed; }
    .agentMaterials__radioButtons, .agentMaterials__selectGroup, .agentMaterials__button {
      display: table-cell; }
      .contactSection__group {
        width: 50%; }
      .largeImageSection__content {
        display: table-cell; }
      .largeImageSection__imageCell--mobile {
        display: none; }
        .largeImageSection__imageCell--desktop {
          display: table-cell;
          vertical-align: top;
          background-position: top; }
    .largeImageSection--imageHalf .largeImageSection__content,
    .largeImageSection--imageHalf .largeImageSection__imageCell {
      width: 50%; }
    .largeImageSection--imageThird .largeImageSection__content {
      width: 66%; }
    .largeImageSection--imageThird .largeImageSection__imageCell {
      width: 34%; }
      .largeImageSection--imageFull {
        min-height: 250px; }
      .largeImageSection--imageFull img {
        min-height: 250px; }
    .pdf iframe {
      height: calc(100vh - 50px); }
      .news__buttons a.news__button {
        width: calc(25% - 7.7px);
        margin-top: 0; }
    .ax-layout-columns .ax-layout-columns--2col50 .ax-layout-column {
      width: 100%; }
  .sidebar {
    width: 28.5714%;
    float: left; }
  .main {
    width: 71.4286%;
    float: left; }
  .page-root .main {
    width: 100%; }
    .changePassword--left-col {
      width: 180px;
      display: table-cell; }
    .changePassword--right-col {
      width: auto;
      display: table-cell;
      padding-left: 30px;
      vertical-align: middle; }
    .changePassword input.changePassword--submit {
      margin: 25px auto 0 auto; } }
  @media (min-width: 992px) {
        .topnav__links img {
          display: none; }
      .topnav__search {
        display: inline; }
      .topnav__searchArrow {
        display: block; }
    .menu-btn {
      display: none; }
    .responsive-menu {
      display: block; }
      .responsive-menu a {
        padding: 5px 10px; }
        .responsive-menu .outer-list__arrow {
          display: none; }
      .responsive-menu .outer-list {
        float: left;
        border-right: 1px solid #FFF;
        border-bottom: none; }
      .responsive-menu .outer-list:hover {
        background-color: #8EB4E0; }
        .responsive-menu .outer-list > a {
          width: 100%; }
        .responsive-menu .outer-list ul {
          position: absolute;
          left: -9999px;
          display: block; }
        .responsive-menu .outer-list.hover ul {
          position: absolute; }
        .responsive-menu .inner-list a {
          text-align: left; }
      .welcome--announcements {
        margin-top: 25px; }
        .quickLinks__sub-menu li {
          padding: 6px 20px; }
  .contentComponentWrapper {
    margin: 20px -20px 0 -20px; }
  .contentComponent {
    display: table;
    table-layout: fixed;
    width: 100%;
    border-spacing: 20px 10px; }
  .contentComponent .contentRow {
    display: table-row; }
    .contentComponent .contentContainer {
      display: table-cell;
      width: 50%;
      margin: 0;
      vertical-align: top; }
      .contentComponent .contentContainer__content {
        padding: 10px 0; }
        .contentComponent .contentContainer__contentCol {
          width: 50%;
          padding: 0 30px 40px; }
    .contentComponent .contentContainer--fullWidth {
      width: 100%; }
      .contentComponent .contentContainer--salesTile {
        width: 33%;
        max-width: 33%; }
        .contentComponent .contentContainer--salesTile .contentContainer__button {
          bottom: 30px; }
  .contentComponent--generalContent {
    display: block;
    width: 100%;
    border-spacing: 20px 10px; }
  .contentComponent--generalContent .contentRow {
    display: block; }
    .contentComponent--generalContent .contentContainer {
      display: block;
      width: 100%; }
  .contentComponent--singleTile {
    display: block;
    margin-bottom: 10px; }
  .contentComponent--singleTile .contentRow {
    display: block; }
    .contentComponent--singleTile .contentRow .contentContainer {
      width: 100%; }
      .contentComponent--singleTile .contentRow .contentContainer__content {
        padding: 10px 20px; }
      .footer__image {
        padding: 0 20px 20px 20px; }
      .agentMaterials .materialsTable__titleCell {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
        .agentMaterials .materialsTable__titleCell .imageBlock {
          float: left;
          width: 30%; }
        .agentMaterials .materialsTable__titleCell .titleBlock {
          width: 70%; }
    .ax-layout-columns .ax-layout-columns--2col50 .ax-layout-column {
      width: 50%;
      float: left; }
    .changePassword--right-col {
      padding-left: 70px;
      padding-right: 30px; } }
  @media (max-width: 992px) {
  .contentComponent--singleTile .contentRow {
    margin: 0 !important; } }
  @media (max-width: 768px) {
      .login .left-column {
        width: 100%; }
      .login .right-column {
        display: none; } }
